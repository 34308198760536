import React, { Component } from "react";
import FormStyle from './FormStyle';

class ContactTwo extends Component {
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Connect With Us!</span>
                                <h2 className="title">Contact Us For More Info</h2>
                                <div className="im_address">
                                    <span>Call Here:</span>
                                    <a className="link im-hover" href="tel:+15189829853">+1 518 982 9853</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Email Here:</span>
                                    <a className="link im-hover" href="mailto:info.drivewize@gmail.com">info.drivewize@gmail.com</a>
                                </div>
                            </div>
                            <FormStyle />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            {/* Map container adjusted to 800x900 dimensions */}
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.032819536543!2d-73.98542848459656!3d40.74881767932762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1619523116257!5m2!1sen!2s"
                                    width="800"
                                    height="900"
                                    style={{ border: '0', width: '600px', height: '630px' }} // Explicitly set dimensions here
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="New York Map"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactTwo;
