import React ,{ Component }from "react";
import {  FiCornerLeftUp, FiHeart , FiLayout , FiNavigation , FiUsers , FiUser,} from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUser />,
        title: 'Driver Behavior',
        description: 'Monitors and analyzes driving habits to promote safer driving practices while behind the wheel.'
    },
    {
        icon: <FiHeart />,
        title: 'Car Diagnostics',
        description: 'Provides real-time insights into the car\'s health, identifying potential issues before they escalate.'
    },
    {
        icon: <FiLayout />,
        title: 'Drivers Report',
        description: 'Compiles comprehensive reports on driving patterns, helping drivers improve efficiency and safety.'
    },
    { 
        icon: <FiCornerLeftUp />,
        title: 'Fuel Management',
        description: 'Optimizes fuel usage with tracking and analysis, leading to cost savings and reduced emissions.'
    },
    {
        icon: <FiNavigation />,
        title: 'Navigation System',
        description: 'Offers advanced routing options with real-time traffic updates for efficient journey planning.'
    },
    { 
        icon: <FiUsers />,
        title: 'Predictive Behavior',
        description: 'Utilizes AI to predict potential driving risks, enhancing preventative measures for driver safety.'
    }
];


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                           
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
